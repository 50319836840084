<template>
  <div class="RepreneurProfile" >
    <div class="container">
      <h1 class="gavc-h1 text-center mb-4">Editer mon profil</h1>
      <b-form v-on:submit.prevent="modifyRepreneurProfile()">
        <div class="row">
          <div class="col-12 text-right">
            <button
              class="gavc-btn mt-4"
              type="submit"
              :disabled="isInvalidRepreneur">
                Enregistrer
            </button>
          </div>
        </div>
        <h2 class="gavc-h2"> Vos informations </h2>
        <div class="row mt-3">
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.firstName.$invalid }">
              <label class="gavc-label" for="registration-step-1-firstName-group">
                Prénom
                <span class="gavc-required"> * </span>
              </label>
              <input
                class="gavc-input"
                id="registration-step-1-firstName-group"
                maxlength="128"
                @input="$v.firstName.$touch()"
                type="text"
                v-model="firstName"
              >
              <inputErrorValidationMessage
                v-if="$v.firstName.$invalid"
                message="Votre prénom doit comporter entre 2 et 128 caractères"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.lastName.$invalid }">
              <label class="gavc-label" for="registration-step-1-lastName-group">
                Nom
                <span class="gavc-required"> * </span>
              </label>
              <input
                class="gavc-input"
                id="registration-step-1-lastName-group"
                maxlength="128"
                @input="$v.lastName.$touch()"
                type="text"
                v-model="lastName"
              >
              <inputErrorValidationMessage
                v-if="$v.lastName.$invalid"
                message="Votre nom doit comporter entre 2 et 128 caractères"
              />
            </div>
          </div>
        </div>


        <div
          class="gavc-field mt-3"
          v-bind:class="{ 'is-error': $v.address.$invalid }">
          <label class="gavc-label" for="registration-step-1-address-group">
              Adresse
              <span class="gavc-required"> * </span>
              <i
                class="gavc-text-martinique gavc-icon gavc-icon-bulb"
                v-b-tooltip.hover.right="{ variant: 'light' }"
                title="Veuillez indiquer votre adresse personnelle (l’adresse de votre domicile).">
              </i>
          </label>
          <input
            class="gavc-input"
            id="registration-step-1-address-group"
            maxlength="250"
            @input="$v.address.$touch()"
            type="text"
            v-model="address"
          >
          <inputErrorValidationMessage
            v-if="$v.address.$invalid"
            message="Votre adresse doit comporter entre 2 et 250 caractères"
          />
        </div>

        <div class="row mt-3">
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.zipCode.$invalid }">
              <label class="gavc-label" for="registration-step-1-zipCode-group">
                Code postal
                <span class="gavc-required"> * </span>
              </label>
              <input
                class="gavc-input"
                id="registration-step-1-zipCode-group"
                maxlength="5"
                @input="$v.zipCode.$touch()"
                type="text"
                v-model="zipCode"
              >
              <inputErrorValidationMessage
                v-if="$v.zipCode.$invalid"
                message="Votre code postal doit être composé de 5 chiffres"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.city.$invalid }">
              <label class="gavc-label" for="registration-step-1-city-group">
                Ville
                <span class="gavc-required"> * </span>
              </label>
              <input
                class="gavc-input"
                id="registration-step-1-city-group"
                maxlength="128"
                @input="$v.city.$touch()"
                type="text"
                v-model="city"
              >
              <inputErrorValidationMessage
                v-if="$v.city.$invalid"
                message="Votre ville doit comporter entre 2 et 128 caractères"
              />
            </div>
          </div>
        </div>

        <div class="gavc-field mt-3">
          <label
            class="gavc-label"
            for="registration-step-1-birthdate-input"
            v-bind:class="{ 'is-error': $v.birthdate.$invalid }"
          >
            Date de naissance
            <span class="gavc-required"> * </span>
          </label>
          <div class="gavc-field gavc-field--icon">
            <input
              @input="$v.birthdate.$touch()"
              class="gavc-input gavc-input--icon gavc-input--calendar"
              id="registration-step-1-birthdate-input"
              type="date"
              v-model="birthdate"
            >
            <i class="gavc-icon gavc-icon-calendar" ></i>
          </div>
          <inputErrorValidationMessage
            v-if="$v.birthdate.$invalid"
            message="Veuillez renseigner votre date de naissance"
          />
        </div>

        <div
          class="gavc-field mt-3"
          v-bind:class="{ 'is-error': $v.birthplace.$invalid }">
          <label class="gavc-label" for="registration-step-1-birthplace-group">
              Lieu de naissance
              <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            id="registration-step-1-birthplace-group"
            maxlength="150"
            @input="$v.birthplace.$touch()"
            type="text"
            v-model="birthplace"
          >
          <inputErrorValidationMessage
            v-if="$v.birthplace.$invalid"
            message="Veuillez renseigner votre lieu de naissance (entre 2 et 150 caractères)"
          />
        </div>

        <div
          class="gavc-field mt-3"
          v-bind:class="{ 'is-error': $v.nationalite.$invalid }">
          <label class="gavc-label" for="registration-step-1-nationalite-group">
              Nationalité
              <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            id="registration-step-1-nationalite-group"
            maxlength="150"
            @input="$v.nationalite.$touch()"
            type="text"
            v-model="nationalite"
          >
          <inputErrorValidationMessage
            v-if="$v.nationalite.$invalid"
            message="Veuillez renseigner votre nationalité (entre 2 et 150 caractères)"
          />
        </div>


        <div
          class="gavc-field"
          v-bind:class="{ 'is-error': $v.phoneNumber.$invalid }"
        >
          <label class="gavc-label" for="registration-step-1-phoneNumber-group">
            Téléphone mobile
            <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            id="registration-step-1-phoneNumber-group"
            maxlength="10"
            @input="$v.phoneNumber.$touch()"
            type="tel"
            placeholder="06 __ __ __ __"
            v-model="phoneNumber"
          >
          <inputErrorValidationMessage
            v-if="$v.phoneNumber.$invalid"
            message="Votre numéro de téléphone doit être composé de 10 chiffres"
          />
        </div>

        <h2 class="gavc-h2 mt-4"> Votre projet de reprise </h2>
        <div
          class="gavc-field">
          <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
            <div class="gavc-radio-icon" v-for="option in repreneurPersonalProjectOptions" :key="option.value">
              <input v-model="personalProject"  type="radio" :id="`registration-step-1-${option.value}`" :name="`registration-step-1-${option.value}`" :value="option.value">
              <label :for="`registration-step-1-${option.value}`">
                  <i :class="`gavc-icon gavc-icon-${option.icon}`"></i>
                  {{option.text}}
              </label>
            </div>
          </div>
          <inputErrorValidationMessage
            v-if="$v.personalProject.$invalid"
            message="Veuillez renseigner cette information"
          />
        </div>

        <div v-if="personalProject === 'AUTRE'"
             class="gavc-field"
             v-bind:class="{ 'is-error': $v.personalProjectPrecision.$dirty ? $v.personalProjectPrecision.$error : null  }">
          <label class="gavc-label" for="registration-step-1-personalProjectPrecision-group">
            Veuillez préciser votre projet de reprise :
          </label>
          <textarea
            class="gavc-textarea"
            id="registration-step-1-personalProjectPrecision-group"
            maxlength="1000"
            placeholder="Décrivez votre projet de reprise"
            @input="$v.personalProjectPrecision.$touch()"
            type="text"
            v-model="personalProjectPrecision">
          </textarea>
          <inputErrorValidationMessage
            v-if="$v.personalProjectPrecision.$dirty ? $v.personalProjectPrecision.$error : null"
            message="Veuillez renseigner cette information"
          />
        </div>

        <p class="gavc-label mt-3">
          Disposez-vous d’une formation dans le domaine d’activités de l’entreprise que vous souhaitez reprendre ?
          <span class="gavc-required"> * </span>
        </p>
        <div class="gavc-field">
          <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
            <div class="gavc-radio-icon">
              <input :value="true" type="radio" id="hasFormationDomaineActivite-input-1"
                     name="registration-step-1-hasFormationDomaineActivite" v-model="hasFormationDomaineActivite">
              <label class="gavc-label" for="hasFormationDomaineActivite-input-1">
                Oui
              </label>
            </div>
            <div class="gavc-radio-icon">
              <input :value="false" type="radio" id="hasFormationDomaineActivite-input-2"
                     name="registration-step-1-hasFormationDomaineActivite" v-model="hasFormationDomaineActivite">
              <label class="gavc-label" for="hasFormationDomaineActivite-input-2">
                Non
              </label>
            </div>
          </div>
          <inputErrorValidationMessage
            v-if="$v.hasFormationDomaineActivite.$invalid "
            message="Veuillez renseigner cette information"
          />
        </div>


        <div v-if="hasFormationDomaineActivite"
             class="gavc-field"
             v-bind:class="{ 'is-error': $v.formationPrecision.$dirty ? $v.formationPrecision.$error : null  }">
          <label class="gavc-label" for="registration-step-1-formationPrecision-group">
            Veuillez préciser la formation (expériences, diplômes, etc.) dont vous disposez :
          </label>
          <textarea
            class="gavc-textarea"
            id="registration-step-1-formationPrecision-group"
            maxlength="1000"
            placeholder="Décrivez votre formation"
            @input="$v.formationPrecision.$touch()"
            type="text"
            v-model="formationPrecision">
          </textarea>
          <inputErrorValidationMessage
            v-if="$v.formationPrecision.$dirty ? $v.formationPrecision.$error : null"
            message="Veuillez renseigner cette information"
          />
        </div>


        <p class="gavc-label mt-3">
          Avez-vous déjà eu une expérience dans la gestion d’entreprise ?
          <span class="gavc-required"> * </span>
        </p>
        <div class="gavc-field">
          <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
            <div class="gavc-radio-icon">
              <input :value="true" type="radio" id="hasExperienceGestionEntreprise-input-1"
                     name="registration-step-1-hasExperienceGestionEntreprise" v-model="hasExperienceGestionEntreprise">
              <label class="gavc-label" for="hasExperienceGestionEntreprise-input-1">
                Oui
              </label>
            </div>
            <div class="gavc-radio-icon">
              <input :value="false" type="radio" id="hasExperienceGestionEntreprise-input-2"
                     name="registration-step-1-hasExperienceGestionEntreprise" v-model="hasExperienceGestionEntreprise">
              <label class="gavc-label" for="hasExperienceGestionEntreprise-input-2">
                Non
              </label>
            </div>
          </div>
          <inputErrorValidationMessage
            v-if="$v.hasExperienceGestionEntreprise.$invalid"
            message="Veuillez renseigner cette information"
          />
        </div>


        <div class="row">
          <div class="col-12 text-right">
            <button
              class="gavc-btn mt-4"
              type="submit"
              :disabled="isInvalidRepreneur">
                Enregistrer
            </button>
          </div>
        </div>

      </b-form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, maxLength, numeric} from 'vuelidate/lib/validators'

import { APIRepreneur } from '@/api/APIRepreneur'
import { isDate } from '@/validators/validators'
import { repreneurPersonalProjectList } from '@/variables/repreneurPersonalProjectList'

const inputErrorValidationMessage = () => import('@/components/inputs/inputErrorValidationMessage')

const apiRepreneurService = new APIRepreneur()

export default {
  name: 'RepreneurProfile',
  components: {
    inputErrorValidationMessage
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      repreneurId: '',
      repreneurPersonalProjectOptions: repreneurPersonalProjectList,

      address: '',
      birthdate: '',
      birthplace: '',
      city: '',
      firstName: '',
      hasExperienceGestionEntreprise: false,
      hasFormationDomaineActivite: false,
      formationPrecision: '',
      lastName: '',
      nationalite: '',
      personalProject: '',
      personalProjectPrecision: '',
      phoneNumber: '',
      zipCode: ''
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isInvalidRepreneur () {
      if (this.$v.$invalid) {
        return true
      }
      return false
    }
  },
  validations: {
    address: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(255)
    },
    birthdate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    birthplace: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150)
    },
    city: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(127)
    },

    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(30),
      required
    },
    formationPrecision: {
      required: requiredIf(function() {
        return this.hasFormationDomaineActivite
      }),
      minLength: minLength(2),
      maxLength: maxLength(1000)
    },
    hasExperienceGestionEntreprise: {
      required,
    },
    hasFormationDomaineActivite: {
      required,
    },
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required
    },
    nationalite: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150)
    },
    personalProject: {
      required
    },
    personalProjectPrecision: {
      required: requiredIf(function() {
        return this.personalProject === 'AUTRE'
      }),
      minLength: minLength(2),
      maxLength: maxLength(1000)
    },
    phoneNumber: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10)
    },

    zipCode: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(5),
      numeric
    },
  },
  created: function() {
    this.getConnectedRepreneur()
  },
  methods: {

    getConnectedRepreneur () {
      this.$store.commit('loadFromAPI')
      apiRepreneurService.getConnectedRepreneur(this.token)
        .then((result) => {
          this.repreneurId = result.data['id']

          this.address = result.data['address']
          this.birthdate = result.data['birthdate']
          this.birthplace = result.data['birthplace']
          this.city = result.data['city']
          this.formationPrecision = result.data['formation_precision']
          this.hasFormationDomaineActivite = result.data['has_formation_domain_activite']
          this.hasExperienceGestionEntreprise = result.data['has_experience_gestion_entreprise']
          this.nationalite = result.data['nationalite']
          this.personalProject = result.data['personal_project']
          this.personalProjectPrecision = result.data['personal_project_precision']
          this.phoneNumber = result.data['phone_number']
          this.zipCode = result.data['zip_code']
          this.firstName = result.data['user']['first_name']
          this.lastName = result.data['user']['last_name']
        })
        .catch((error) => {
          this.$store.commit('openGlobalErrorModal')
          throw new Error('RepreneurProfile getConnectedRepreneur API Error : ' + String(error))
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },

    modifyRepreneurProfile () {
      this.$store.commit('loadFromAPI')
      const data = {
        "address": this.address,
        "birthdate": this.birthdate,
        "birthplace": this.birthplace,
        "city": this.city,
        'formation_precision': this.formationPrecision,
        'has_formation_domain_activite': this.hasFormationDomaineActivite,
        'has_experience_gestion_entreprise': this.hasExperienceGestionEntreprise,
        "nationalite": this.nationalite,
        'personal_project': this.personalProject,
        'personal_project_precision': this.personalProjectPrecision,
        "phone_number": this.phoneNumber,
        "zip_code": this.zipCode,
        "user": {
          'first_name': this.firstName,
          'last_name': this.lastName
        }
      }
      apiRepreneurService.modifyConnectedRepreneur(this.token, data)
      .then(() => {
        this.$bvToast.hide('notification-toast')
        this.$bvToast.toast('Votre profil a bien été mis à jour', {
          id: 'notification-toast',
          title: 'Profil mis à jour',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('RepreneurProfile modifyRepreneurProfile API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })

    },
  }
}
</script>
