export const repreneurPersonalProjectList = [
  {
    "icon": "man-turn",
    "text": "Vous réalisez une reconversion professionnelle",
    "value": "RECONVERSION"
  },
  {
    "icon": "man-graph",
    "text": "Vous développez une activité existante",
    "value": "DEVELOPPER"
  },
  {
    "icon": "high-five",
    "text": "Vous êtes de la même famille que le dirigeant et reprenez l’activité",
    "value": "FAMILLE"
  },
  {
    "icon": "handshake",
    "text": "Vous reprenez l’activité d’une entreprise dont vous êtes salarié",
    "value": "SALARIE"
  },
  {
    "icon": "other",
    "text": "Autre projet de reprise",
    "value": "AUTRE"
  }
]
